import { defineStore } from 'pinia'
import { ref } from 'vue'
import type OfficeBackend from '@/Backend/models/OfficeBackend'
import { useListOffices } from '@/Query/officeQuery'

export const useOfficeStore = defineStore<
  string,
  {
    offices: Ref<OfficeBackend[]>
    loadOffices: () => Promise<void>
  }
>('offices', () => {
  const offices = ref<OfficeBackend[]>([])
  const loadOffices = async () => {
    useListOffices({
      includes: ['zones']
    }).then((resp) => {
      offices.value = resp.data.value?.data ?? []
    })
  }
  return {
    offices,
    loadOffices
  }
})
