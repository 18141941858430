import 'vite/modulepreload-polyfill'
import i18nInstance from '@/i18n'
import { useAppStore } from '@/Store/AppStore'
import { useUserStore } from '@/Store/UserStore'
import { createApp, h, nextTick } from 'vue'
import { createInertiaApp, Link } from '@inertiajs/vue3'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'
import * as Sentry from '@sentry/vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '../css/app.css'
import './bootstrap'
import dayjs, { dayjsHuman } from './dayjs'
import './axios'
import { createPinia } from 'pinia'
import DOMPurify from 'dompurify'

const pinia = createPinia()

createInertiaApp({
  resolve: (name) => {
    const pages = import.meta.glob('./Pages/**/*.vue')
    return pages[`./Pages/${name}.vue`]()
  },
  setup({ el, App, props, plugin }) {
    const { env } = props.initialPage.props
    const { user } = props.initialPage.props.auth
    const app = createApp({ render: () => h(App, props) })
    app.use(pinia)

    app.provide(
      'isSafari',
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
        /(iPhone|iPod|iPad)/i.test(navigator.userAgent) ||
        window.location.href.includes('/display?signature=')
    )

    Sentry.init({
      app,
      enabled: env === 'production',
      dsn: 'https://af0bc04e5d0f435ba63a4139374f3a99@o1310579.ingest.sentry.io/4504135220854784',
      integrations: [
        Sentry.browserTracingIntegration({
          tracePropagationTargets: [
            'localhost',
            'z61e7d4f5-z74db70b2-gtw.zae5468e7.slab.sh',
            'app.deskare.io',
            /^\//
          ]
        })
      ],
      environment: env,
      trackComponents: true,
      release: import.meta.env.VITE_TAG ?? 'dev',
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      maskAllText: false,
      tracesSampleRate: 0.1,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1,
      ignoreErrors: ['value: cancel', 'CancelledByUser', 'CancelledByUser']
    })

    Sentry.setUser({
      id: user?.id,
      segment: user?.company.uuid
    })

    app
      .use(plugin)
      .component(Link.name, Link)
      .use(VueClipboard)
      .mixin({
        methods: {
          $dayjs(date) {
            return dayjs(date)
          },
          $dayjsHuman(date, short = false) {
            return dayjsHuman(date, short)
          }
        }
      })

    app.config.globalProperties.DOMPurify = DOMPurify
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      if (key === Link.name) {
        continue
      }
      app.component(key, component)
    }
    const removeSplashScreen = () => {
      const splash = document.getElementById('splash-screen')
      if (splash) {
        splash.style.display = 'none'
      }
    }
    app.use(i18nInstance)
    if (
      props.initialPage.component.startsWith('Auth/') ||
      props.initialPage.component.startsWith('Zones/ZoneDisplay') ||
      props.initialPage.component.startsWith('Visitor/VisitorPlan')
    ) {
      useUserStore()
        .initializeLocale()
        .then(() => {
          app.mount(el)
          nextTick(removeSplashScreen)
        })
    } else {
      useUserStore()
        .fetchUser()
        .then(() => {
          useAppStore()
            .bootApp()
            .then(() => {
              app.mount(el)
              nextTick(removeSplashScreen)
            })
        })
    }
  }
})
