import { route } from 'ziggy-js'
import useAxios from '@/useAxios'
import type UserBackend from '@/Backend/models/UserBackend'
import type metadata from '@/Backend/metadata'
import type { UseAxiosOptions } from '@vueuse/integrations'

export function useListUsers<T = { data: UserBackend[]; meta: metadata }>(
  params = {},
  options: UseAxiosOptions<T> | undefined = undefined
) {
  return useAxios<T>(
    route('api.v1.users.list'),
    {
      method: 'get',
      params: params
    },
    {
      immediate: true,
      ...options
    }
  )
}

export function useGetUser<T = { data: UserBackend }>(
  userId: number,
  params = {},
  options: UseAxiosOptions<T> = {}
) {
  return useAxios<T>(
    route('api.v1.users.get', userId),
    {
      method: 'get',
      params: params
    },
    {
      immediate: true,
      ...options
    }
  )
}

export function useUpdateUser<T = { data: UserBackend }>(
  userId: number,
  data = {},
  options: UseAxiosOptions<T> = {}
) {
  return useAxios<T>(
    route('api.v1.users.update', userId),
    {
      method: 'put',
      data: data
    },
    {
      immediate: false,
      ...options
    }
  )
}

export function useUpdateUserPhoto<T = { data: UserBackend }>(
  userId: number,
  data = {},
  options: UseAxiosOptions<T> = {}
) {
  return useAxios<T>(
    route('api.v1.users.photo.update', userId),
    {
      method: 'post',
      data: data
    },
    {
      immediate: false,
      ...options
    }
  )
}

export function useListBankHolidays(userId: number, params = {}) {
  return useAxios(
    route('api.v1.users.bank-holidays.list', userId),
    {
      method: 'get',
      params
    },
    { immediate: true }
  )
}
